.containerCard {
  height: 100vh;
  justify-content: center;
  overflow-x: hidden;
}

.container-1 {
  background-color: #17576370;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px #ffffff95;
  width: 360px;
  height: 400px;
  border-top-left-radius: 20px;
}

.avatarImg {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  opacity: 1;
}

.container-2 {
  background-image: linear-gradient(
    319deg,
    #a7ecff 0%,
    #e0e0f5 37%,
    #2ba4c0 100%
  );

  width: 360px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 3px #ffffff95;
}

.inputFields {
  height: 20px;
  width: 230px;
}

.input-phone {
  display: block;
  margin: 2em auto;
  padding: 0.5em 0.25em;
  border: none;
  border-radius: 0.2em;
  font-size: 1.5em;
  text-align: center;
  box-shadow: 0 0 1em 0.25em rgba(0, 0, 0, 0.2);
}

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.profile-pic .imgChange {
  display: none;
}

.profile-pic .changeProfile {
  position: absolute;
  object-fit: cover;
  width: 165px;
  height: 165px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.profile-pic .-label {
  cursor: pointer;
  height: 165px;
  width: 165px;
}

.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: rgb(250, 250, 250);
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}

.profile-pic span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}

.form-group.groupInputProfile.mb-3.p-2 {
  color: white;
  font-weight: 700;
  margin-bottom: 10px;
}
