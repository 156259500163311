/* .containerbody {
  font-family: 'Poppins', sans-serif;
  align-items: center;
  justify-content: center;
}
.company_logo_img {
  max-width: 100%;
  display: block;
}
ul {
  list-style: none;
}

.cardConatiner::after,
.cardConatiner .company_logo_img {
  border-radius: 50%;
}
.containerbody,
.cardConatiner,
.stats {
  display: flex;
}

.cardConatiner {
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 800px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.cardConatiner::before,
.cardConatiner::after {
  content: '';
  position: absolute;
  z-index: -1;
}
.cardConatiner::before {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  top: -0.7rem;
  left: -0.7rem;
}
.cardConatiner::after {
  height: 15rem;
  width: 15rem;
  background-color: #4095dc64;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #fff;
}

.cardConatiner .company_logo_img {
  width: 70px;
  box-shadow: 0 0 0 5px #fff;
}

.company_details {
  margin-bottom: 1rem;
}
.company_details .companyh2 {
  font-size: 1.3rem;
}
.company_details .companyh4 {
  font-size: 0.8rem;
  color: #333;
}

.textP {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.stats {
  margin-bottom: 1rem;
}
.stats .textli {
  min-width: 5rem;
}
.stats .textli .companyh3 {
  font-size: 0.99rem;
}
.stats .textli .companyh4 {
  font-size: 0.75rem;
}

@media screen and (max-width: 450px) {
  .cardConatiner {
    display: block;
  }
  .infos {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .links .follow .view {
    min-width: 100px;
  }
}

.inputTag-details::placeholder {
  color: #908a8a;
  font-size: 15px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.input-group-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.labelTag-details {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.input-group .labelTag-details {
  color: #131111;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 7px;
}

.inputTag-details {
  font-size: 16px;
  padding: 0.1em 0.25em;
  background-color: hsla(201, 100%, 91%, 0.3);
  border: 1px solid hsla(201, 73%, 45%, 0.054);
  outline: none;
  border-top-left-radius: 8px !important;
  border-bottom-right-radius: 8px;
  color: #0c0a0a;
  font-weight: lighter;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  height: 40px;
}

.input-group .inputTag-details:focus {
  background-color: hsl(268.87deg 95.41% 2.9% / 30%);
}

.button-group {
  padding: 10px 30px;
  width: 100%;
  border-top-left-radius: 8px !important;
  border-bottom-right-radius: 8px;
  background: hsla(220, 71%, 35%, 0.188);
  border: 1px solid hsla(201, 73%, 45%, 0.054);
  outline: none;
  font-size: 1.2em;
  color: #0501019b;
  font-weight: lighter;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.button-group:hover {
  color: white;
  background-color: skyblue;
  border: none;
}

.logo_image {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.logo_image .logoChange {
  display: none;
}

.logo_image .changeLogo {
  position: absolute;
  object-fit: cover;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.logo_image .-labelLogo {
  cursor: pointer;
  height: 100px;
  width: 100px;
}

.logo_image:hover .-labelLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: rgb(250, 250, 250);
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}

.logo_image span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
} */

/* .containerbody {
  font-family: 'Poppins', sans-serif;
  align-items: center;
  justify-content: center;
} */
.company_logo_img {
  max-width: 100%;
  display: block;
}
ul {
  list-style: none;
}

.cardConatiner::after,
.cardConatiner .company_logo_img {
  border-radius: 50%;
}
.containerbody,
.cardConatiner,
.stats {
  display: flex;
}

.cardConatiner {
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 800px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.cardConatiner::before,
.cardConatiner::after {
  content: '';
  position: absolute;
  z-index: -1;
}
.cardConatiner::before {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  top: -0.7rem;
  left: -0.7rem;
}
.cardConatiner::after {
  height: 15rem;
  width: 15rem;
  background-color: #4095dc64;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #fff;
}

.cardConatiner .company_logo_img {
  width: 70px;
  box-shadow: 0 0 0 5px #fff;
}

.company_details {
  margin-bottom: 1rem;
}
.company_details .companyh2 {
  font-size: 1.3rem;
}
.company_details .companyh4 {
  font-size: 0.8rem;
  color: #333;
}

.textP {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.stats {
  margin-bottom: 1rem;
}
.stats .textli {
  min-width: 5rem;
}
.stats .textli .companyh3 {
  font-size: 0.99rem;
}
.stats .textli .companyh4 {
  font-size: 0.75rem;
}

@media screen and (max-width: 450px) {
  .cardConatiner {
    display: block;
  }
  .infos {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .links .follow .view {
    min-width: 100px;
  }
}

.inputTag-details::placeholder {
  color: #908a8a;
  font-size: 15px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.input-group-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.labelTag-details {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.input-group .labelTag-details {
  color: #131111;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 7px;
}

.inputTag-details {
  font-size: 16px;
  padding: 0.1em 0.25em;
  background-color: hsla(201, 100%, 91%, 0.3);
  border: 1px solid hsla(201, 73%, 45%, 0.054);
  outline: none;
  border-top-left-radius: 8px !important;
  border-bottom-right-radius: 8px;
  color: #0c0a0a;
  font-weight: lighter;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  height: 40px;
}

.input-group .inputTag-details:focus {
  background-color: hsl(268.87deg 95.41% 2.9% / 30%);
}

.button-group {
  padding: 10px 30px;
  width: 100%;
  border-top-left-radius: 8px !important;
  border-bottom-right-radius: 8px;
  background: hsla(220, 71%, 35%, 0.188);
  border: 1px solid hsla(201, 73%, 45%, 0.054);
  outline: none;
  font-size: 1.2em;
  color: #0501019b;
  font-weight: lighter;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 700;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
}
.button-group:hover {
  color: white;
  background-color: skyblue;
  border: none;
}

.logo_image {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  position: relative;
  transition: all 0.3s ease;
}

.logo_image .logoChange {
  display: none;
}

.logo_image .changeLogo {
  position: absolute;
  object-fit: cover;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.logo_image .-labelLogo {
  cursor: pointer;
  height: 100px;
  width: 100px;
}

.logo_image:hover .-labelLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: rgb(250, 250, 250);
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}

.logo_image span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}
