/* SuperAdminDashboard.css */
.full-screen-background {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
}

.navbar {
  position: relative;
  z-index: 1;
}

.logo-img {
  width: 50px;
}

.dropdown_activate {
  width: 140px;
  float: right;
}

@media (max-width: 576px) {
  .company-list-name {
    font-size: 24px;
  }
}

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #33c8d6;
  padding-right: 40px;
  color: #0e0e0e;
}
.input-search::placeholder {
  color: rgba(24, 23, 23, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #171616;
  background-color: transparent;
  pointer-events: painted;
}
.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0px;
  background-color: #368ebaac;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
  color: black;
}
.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(19, 18, 18, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
