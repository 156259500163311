.btnEye {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
}

.btnEye:hover {
  color: black;
  font-size: 18px;
  background: none;
}
