.full-screen-background {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  overflow-x: hidden;
}

.placeholder-white::-webkit-input-placeholder {
  color: white;
}
.placeholder-white::-moz-placeholder {
  color: white;
}
.placeholder-white:-ms-input-placeholder {
  color: white;
}
.placeholder-white:-moz-placeholder {
  color: white;
}
